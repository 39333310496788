.App {
  text-align: center; 
  
}
body { background-color: black 
}
#root {
margin: auto 0; 
background-color: azure;
padding-bottom: 40px
}

.mainstreet { padding: 0 10% 0 10%; max-width: 50%; margin: 0 auto  }

.App h2 { color: #FF8719; 
  /* border-top: 1px #FF8719 solid; */ 
  border-top: 1px rgb(250, 221, 189) solid; 
  border-bottom: 1px rgb(250, 221, 189) solid; 
 /* background-color: rgb(253, 238, 222); */
 /* line-height: 200%; */ 
  margin-top: 1em;
  padding: 7px 0 7px 0; 
  margin-bottom: 1em;
  letter-spacing: -1px;
  text-align: left;
  /*
  -webkit-text-stroke: 1px rgb(219, 124, 36),; 
   text-shadow:
      1px 1px 0 rgb(219, 124, 36),
    -1px -1px 0 rgb(219, 124, 36),
     1px -1px 0 rgb(219, 124, 36),
     -1px 1px 0 rgb(219, 124, 36),
      1px 1px 0 rgb(219, 124, 36)
  */
 }
 

 h2 .obnox {
  font-style: italic; font-family: serif !important; margin-left: -1px;
  text-shadow: none; -webkit-text-stroke: none
 }

 h2.mccsc { color: #00467f }
 h2.iu { color: #990000 }
 
 p { text-align: left; line-height: 1.5em } 
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
ul, li { list-style-type: none; padding-left: 0 !important ; margin-left: 0 !important }
a { text-decoration: none; color: #53C2CC  } a:hover { color: orange }

body span.ital { font-style: italic; font-family: serif !important; font-size: 110% }
.App span.orangish { color: orange}

/* h1, h2, header img, p { max-width: 90% } */

.App-header {
  /*background: #222 url("https://iu.box.com/shared/static/9n7nvfk7q4ivnrx6dfot5uoi1m5f3qjy.jpg") no-repeat center; */
  background: purple url("header.jpg") no-repeat top;
 max-height: 33%; 

  padding: 20px;
  color: white;
  margin-bottom: 2em;
}

.App-title {
  font-size: 1.5em;
}

.pluralsight { max-width: 33%; max-height: 33% }

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
  /* Styles */
  .App-header {
    max-height: 33% 
  }
  .mainstreet { 
    max-width: 70% !important;
  }

  p, .App h2 { text-align: center }

  .App h2 {

  border-top: none;
  border-bottom: none; 
  }
  
}
